import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../components/layout/Layout"
import StoreView from "../components/views/stores"
import { AccountContext } from "../context/account"
import LoadingContainer from "../components/loading-container"
import { ArrowPathIcon } from "@heroicons/react/24/outline"

const IndexPage = () => {
  const {
    render
  } = useContext(AccountContext)
  return (
    <DndProvider backend={HTML5Backend}>
      {
        !render ?
          <div className="h-screen">
            <div className="h-full grid">
              <div className="place-self-center">
                <ArrowPathIcon className="w-20 h-20 animate-spin" />
              </div>
            </div>
          </div> : <StoreView />
      }
    </DndProvider>
  )
}

export default IndexPage
