import React from "react"
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Select,
  Option,
  Input,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react"
import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
  ChevronRightIcon,
  LockClosedIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import StoreController from "../../../services/store"
import { toast } from "react-hot-toast"
import { useForm, Controller } from "react-hook-form"
import VirtualScroll from "react-dynamic-virtual-scroll"
import { navigate } from "gatsby"
import StoreTab from "./store-tab"
import CreatorTab from "./creator-tab"

const StoreView = () => {
  return (
    <React.Fragment>
      <div className="h-screen bg-gradient-to-r from-cyan-500 to-blue-500">
        <div className=" p-4 h-full grid grid-cols-3  content-center">
          <div></div>
          <Tabs id="custom-animation" value="store" >
            <TabsHeader >
              <Tab value={'store'} defaultChecked={true}>
                Store
              </Tab>
              <Tab value={'creator'} defaultChecked={true}>
                Creator
              </Tab>
            </TabsHeader>
            <TabsBody
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
              className='p-0'
            >
              <TabPanel value={'store'} className='px-0 py-2'>
                <StoreTab />
              </TabPanel>
              <TabPanel value={'creator'} className='px-0 py-2'>
                <CreatorTab />
              </TabPanel>
            </TabsBody>
          </Tabs>

        </div>
      </div>


    </React.Fragment>
  )
}

export default StoreView
