import React from "react"
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Select,
  Option,
  Input,
} from "@material-tailwind/react"
import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
  ChevronRightIcon,
  LockClosedIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import CreatorController from "../../../services/creator"
import { toast } from "react-hot-toast"
import { useForm, Controller } from "react-hook-form"
import VirtualScroll from "react-dynamic-virtual-scroll"
import { navigate } from "gatsby"
import { useMediaQuery } from "usehooks-ts"


export default function CreatorTab() {
  const matches = useMediaQuery('(min-width: 768px)')
  const [stores, setStores] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [countries, setCountries] = React.useState([])
  const [showCreateStore, setShowCreateStore] = React.useState(false)
  const [creator, setCreator] = React.useState<any>(null)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const getCountries = async () => {
    const response = await CreatorController.getCountries()
    if (response instanceof Error) {
      toast.error("Có lỗi xảy ra")
      return
    }

    setCountries(response.data?.result)
  }

  const getCreator = async () => {
    const response = await CreatorController.getCreatorOfUser()
    if (response instanceof Error) {
      return
    }

    setCreator(response.data?.result)
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const result = await CreatorController.create({
      fullName: data.name,
      countryId: data?.country
    })
    setLoading(false)
    if (result instanceof Error) {
      toast.error("Có lỗi xảy ra")
      return
    }
    toast.success("Register succeed")
    setShowCreateStore(false)
  }

  React.useEffect(() => {
    getCountries()
    // getStores()
    getCreator()
  }, [])

  return (
    <>
      <div className="bg-white rounded-md shadow-lg p-6">
        <div className="flex justify-between">
          <div className="font-bold text-xl py-2">Creator</div>
          <Button
            variant="gradient"
            onClick={() => {
              setShowCreateStore(true)
            }}
            disabled={creator}
          >
            <span>Register creator</span>
          </Button>
        </div>
        <div className="border-t border-gray-300 my-5"></div>

        {
          creator && <div className="max-h-96 overflow-y-scroll">
            <React.Fragment >
              <Button
                onClick={() => {
                  return navigate(
                    `/creator/insight`
                  )
                }}
                variant="outlined"
                color="white"
                className="border-lg text-black py-2 my-2 rounded-md shadow-md px-4 w-full"
              >
                <div className="grid grid-cols-12   content-center">
                  <div className="col-span-2 py-4">
                    <BuildingStorefrontIcon className="h-6 w-6 text-gray-400" />
                  </div>
                  <div className="col-span-9 py-[12px] text-left">
                    {creator?.name}
                    <br />
                    <span className="text-gray-600 text-xs font-normal capitalize">
                      @{creator?.slug}
                    </span>
                  </div>

                  <div className="py-4">
                    <ChevronRightIcon className="h-4 mt-[5px] " />
                  </div>
                </div>
              </Button>
            </React.Fragment>
          </div>
        }

      </div>

      <Dialog
        open={showCreateStore}
        // className="w-full lg:w-1/2"
        size={!matches ? "lg" : "md"}
      // size="lg"

      // handler={handleOpen}
      >
        <DialogHeader className="justify-between">
          {/* <div className="flex justify-between items-center"> */}
          <div>Create creator </div>{" "}
          <div>
            <Button
              onClick={() => setShowCreateStore(false)}
              variant="text"
              className="border-none outline-none"
              size="sm"
            >
              <XMarkIcon className="h-4" />
            </Button>
          </div>
          {/* </div> */}
        </DialogHeader>

        <DialogBody>
          <form className="mt-4 mb-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 flex flex-col gap-6">
              <Controller
                control={control}
                name="country"
                rules={{ required: "Please enter value" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div>
                    <Select
                      size="lg"
                      label="Select country"
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                      error={errors?.category?.message || false}
                    >

                      {countries.length > 0 && countries.map((item: any, index: number) => {
                        return (<Option value={item.id} key={item.id}>
                          {item.countryName}
                        </Option>)
                      })
                      }
                      {/* <Option value={null}>asd</Option> */}

                    </Select>
                    <div className="text-red-500">
                      {errors?.category?.message}
                    </div>
                  </div>
                )}
              />
              <Controller
                control={control}
                name="name"
                rules={{ required: "Please enter value" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div>
                    <Input
                      error={errors.name?.message || false}
                      size="lg"
                      label="Full name"
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      inputRef={ref}
                      className='capitalize'
                    />
                    <div className="text-red-500">{errors.name?.message}</div>
                  </div>
                )}
              />

            </div>

            <Button
              type="submit"
              className="flex items-center gap-3 justify-center"
              fullWidth
              disabled={loading}
            >
              {loading && (
                <ArrowPathIcon
                  strokeWidth={2}
                  className="h-5 w-5 animate-spin"
                />
              )}{" "}
              Register
            </Button>
          </form>
        </DialogBody>
      </Dialog>
    </>
  )
}