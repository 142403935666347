import React from "react"
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Select,
  Option,
  Input,
} from "@material-tailwind/react"
import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
  ChevronRightIcon,
  LockClosedIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import StoreController from "../../../services/store"
import { toast } from "react-hot-toast"
import { useForm, Controller } from "react-hook-form"
import VirtualScroll from "react-dynamic-virtual-scroll"
import { navigate } from "gatsby"


export default function StoreTab() {
  const [stores, setStores] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [categories, setCategories] = React.useState([])
  const [regions, setRegions] = React.useState([])
  const [showCreateStore, setShowCreateStore] = React.useState(false)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const getCategories = async () => {
    const response = await StoreController.getMcc()
    if (response instanceof Error) {
      toast.error("Có lỗi xảy ra")
      return
    }
    setCategories(response.data)
  }

  const getStores = async () => {
    setLoading(true)
    const response = await StoreController.getStores()
    setLoading(false)
    if (response instanceof Error) {
      toast.error("Có lỗi xảy ra")
      return
    }
    setStores(response.result?.stores || [])
  }

  const getRegions = async () => {
    setLoading(true)
    const response = await StoreController.getRegions()
    setLoading(false)
    if (response instanceof Error) {
      toast.error("Có lỗi xảy ra")
      return
    }

    setRegions(response?.regions || [])
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const result = await StoreController.createStore({
      storeName: data.name,
      mccId: data?.category,
      regionId: data?.region
    })
    setLoading(false)
    getStores()
    if (result instanceof Error) {
      toast.error("Có lỗi xảy ra")
      return
    }
    toast.success("Create store succeed")
    setShowCreateStore(false)
  }

  React.useEffect(() => {
    getCategories()
    getStores()
    getRegions()
  }, [])
  return (
    <>
      <div className="bg-white rounded-md shadow-lg p-6">
        <div className="flex justify-between">
          <div className="font-bold text-xl py-2">Stores</div>
          <Button
            variant="gradient"
            onClick={() => {
              setShowCreateStore(true)
            }}
          >
            <span>Add another store</span>
          </Button>
        </div>
        <div className="border-t border-gray-300 my-5"></div>

        <div className="max-h-96 overflow-y-scroll">
          {stores?.length > 0 &&
            stores?.map((store, index): any => {
              return (
                <React.Fragment key={index}>
                  <Button
                    onClick={() => {
                      window.localStorage.setItem(
                        "current_store_id",
                        store?.id
                      )
                      return navigate(
                        // `/${store?.mcc?.slug ? store?.mcc?.slug : "retail"
                        // }/dashboard`
                        store?.mcc?.slug ? `stores/dashboard` : `retail/dashboard`
                      )
                    }}
                    variant="outlined"
                    color="white"
                    className="border-lg text-black py-2 my-2 rounded-md shadow-md px-4 w-full"
                  >
                    <div className="grid grid-cols-12   content-center">
                      <div className="col-span-2 py-4">
                        <BuildingStorefrontIcon className="h-6 w-6 text-gray-400" />
                      </div>
                      <div className="col-span-9 py-[12px] text-left">
                        {store?.name}
                        <br />
                        <span className="text-gray-600 text-xs font-normal capitalize">
                          {store?.mcc?.name}
                        </span>
                      </div>

                      <div className="py-4">
                        <ChevronRightIcon className="h-4 mt-[5px] " />
                      </div>
                    </div>
                  </Button>
                </React.Fragment>
              )
            })}
        </div>
      </div>

      <Dialog
        open={showCreateStore}
        size="md"
      // handler={handleOpen}
      >
        <DialogHeader className="justify-between">
          {/* <div className="flex justify-between items-center"> */}
          <div>Create new store </div>{" "}
          <div>
            <Button
              onClick={() => setShowCreateStore(false)}
              variant="text"
              className="border-none outline-none"
              size="sm"
            >
              <XMarkIcon className="h-4" />
            </Button>
          </div>
          {/* </div> */}
        </DialogHeader>

        <DialogBody>
          <form className="mt-4 mb-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 flex flex-col gap-6">
              <Controller
                control={control}
                name="region"
                rules={{ required: "Please enter value" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div>
                    <Select
                      size="lg"
                      label="Select region"
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      // checked={value}
                      ref={ref}
                      error={errors?.region?.message || false}
                    >
                      {regions.length > 0 &&
                        regions.map((item: any, index: number) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          )
                        })}
                    </Select>
                    <div className="text-red-500">
                      {errors?.region?.message}
                    </div>
                  </div>
                )}
              />
              <Controller
                control={control}
                name="category"
                rules={{ required: "Please enter value" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div>
                    <Select
                      size="lg"
                      label="Select category"
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      // checked={value}
                      ref={ref}
                      error={errors?.category?.message || false}
                    >
                      {categories.length > 0 &&
                        categories.map((item: any, index: number) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          )
                        })}
                    </Select>
                    <div className="text-red-500">
                      {errors?.category?.message}
                    </div>
                  </div>
                )}
              />
              <Controller
                control={control}
                name="name"
                rules={{ required: "Please enter value" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <div>
                    <Input
                      error={errors.name?.message || false}
                      size="lg"
                      label="Store name"
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={value}
                      inputRef={ref}
                      className="capitalize"
                    />
                    <div className="text-red-500">{errors.name?.message}</div>
                  </div>
                )}
              />

            </div>

            <Button
              type="submit"
              className="flex items-center gap-3 justify-center"
              fullWidth
              disabled={loading}
            >
              {loading && (
                <ArrowPathIcon
                  strokeWidth={2}
                  className="h-5 w-5 animate-spin"
                />
              )}{" "}
              Create store
            </Button>
          </form>
        </DialogBody>
      </Dialog></>
  )
}