import { makeFormRequest, makeFormURLRequest, makeRequest } from "../request"
import qs from "qs"

interface ILogin {
  email: string
  password: string
}

interface IRegister {
  email: string
  password: string
  confirm_password: string
}

let token = ""
if (typeof window !== "undefined") {
  token = window.sessionStorage.getItem("hacker_you") || ""
}
class CreatorController {
  constructor() { }

  getCountries = async () => {
    return await makeRequest(
      "get",
      "/api/v1/creators/services/country",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
  }

  create = async ({ fullName, countryId }) => {
    return await makeRequest(
      "post",
      "/api/v1/creators/services",
      {
        countryId,
        name: fullName,
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getBanks = async () => {
    return await makeRequest(
      "get",
      "/api/v1/creators/banks/VND",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
  }

  addPayoutMethod = async (data: { accountNumber: string, accountName: string }, bank: string, currency: string) => {
    const requestData: any = { ...data }
    switch (currency) {
      case 'VND':
        requestData.vnBank = {
          id: bank,
        };
        break;

      default:
        break;
    }
    return await makeRequest(
      "post",
      `/api/v1/creators/services/banks/payouts/${currency}`,
      requestData,
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getPayouts = async () => {
    // const requestData: any = { ...data }
    // switch (currency) {
    //   case 'VND':
    //     requestData.vnBank = {
    //       id: bank,
    //     };
    //     break;

    //   default:
    //     break;
    // }
    return await makeRequest(
      "get",
      `/api/v1/creators/services/banks/payouts`,
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getTransactions = async () => {
    return await makeRequest(
      "get",
      "/api/v1/creators/transactions",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
  }

  getCurrentPaymentMethods = async () => {
    return await makeRequest(
      "get",
      "/api/v1/creators/payment-methods",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
  }

  updatePaymentMethod = async ({
    id = "",
    status = 1
  }) => {
    return await makeRequest(
      "post",
      `/api/v1/creators/payment-methods/update`,
      {
        id,
        status
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getCreatorOfUser = async () => {
    return await makeRequest(
      "get",
      "/api/v1/creators/services",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
  }

  genDntToken = async () => {
    return await makeRequest(
      "get",
      `/api/v1/creators/services/token/gen`,
      {
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  uploadResources = async (data) => {
    return await makeFormRequest(
      `/api/v1/creators/resources/upload`,
      data,
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getVirtualDonate = async (dntToken: string) => {
    return await makeRequest(
      "get",
      `/api/v1/creators/donates/virtual/${dntToken}`,
      {
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  updateOthersSettings = async (data) => {
    return await makeRequest(
      "post",
      `/api/v1/creators/donates/settings/update-others`,
      data,
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

}

export default new CreatorController()
